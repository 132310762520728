import axios from 'axios'
import $ from 'jquery'
import Cookies from 'js-cookie'
import React from 'react'
import { Helmet } from "react-helmet"
import { Redirect } from "react-router-dom"
import tinycolor from "tinycolor2"
import GlobalField from '../../Classes/GlobalField'
import { apiConfig } from '../../Classes/Helper.jsx'
import Hero from '../../Classes/Hero'
import HeroSubNav from '../../Classes/HeroSubNav'
import LargeCopyWithDetails from '../../Classes/LargeCopyWithDetails'
import MediaFeaturedGrid from '../../Classes/MediaFeaturedGrid'
import NhsDesktop from '../../Classes/NhsDesktop'
import PromotionBlock from '../../Classes/PromotionBlock'
import SkeletonComp from '../../Classes/SkeletonComp.jsx'
import UtilityNav from '../../Classes/UtilityNav'
import NoMatch from './NoMatch'

class Community extends React.Component {
  state ={
    community_component : [],
    success : false,
    message: ''
  }

  constructor() {
    super()
  }

  /*Local Business*/
  local_business_structured_data(communityComponent) {
    if (communityComponent) {
      if (communityComponent.agent_info.agents_contact_phone) {
        var telephone = communityComponent.agent_info.agents_contact_phone
      }

      if (communityComponent.min_price) {
        var min_price = communityComponent.min_price
      }

      if (communityComponent.max_price) {
        var max_price = communityComponent.max_price
      }

      var priceRange = min_price + ' - ' + max_price

      if (communityComponent.physical_address.physical_address_heading) {
        var addressLocality = communityComponent.physical_address.physical_address_heading.replace( /(<([^>]+)>)/ig, '')
      }

      if (communityComponent.streetAddress) {
        var streetAddress = communityComponent.streetAddress
      }

      if (communityComponent.postalCode) {
        var postalCode = communityComponent.postalCode
      }

      if (communityComponent.latitude) {
        var latitude = communityComponent.latitude
      }

      if (communityComponent.longitude) {
        var longitude = communityComponent.longitude
      }

      if (communityComponent.state_abb) {
        var state_abb = communityComponent.state_abb.toUpperCase()
      }

      if (communityComponent.site_logo) {
        var site_logo = communityComponent.site_logo
      }
    }

    const script = document.createElement("script")

    // Structure Data script start
    script.type = "application/ld+json"

    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "Tri Pointe Homes",
      "image": "${site_logo}",
      "@id": "",
      "url": "${window.location.href}",
      "telephone": "${telephone}",
      "priceRange": "${priceRange}",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "${streetAddress}",
        "addressLocality": "${addressLocality}",
        "addressRegion": "${state_abb}",
        "postalCode": "${postalCode}",
        "addressCountry": "US"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "${latitude}",
        "longitude": "${longitude}"
      }
    }`

    //  Structure Data script end
    document.head.appendChild(script)
  }

  local_product_structured_data(communityComponent) {
    if (communityComponent) {
      if (communityComponent.title) {
        var comm_title = communityComponent.title
      }

      if (communityComponent.min_price) {
        var min_price = communityComponent.min_price
      } else {
        if (communityComponent.community_resi_min_price) {
          var min_price = communityComponent.community_resi_min_price
        }
      }

      if (communityComponent.max_price) {
        var max_price = communityComponent.max_price
      } else {
        if (communityComponent.community_resi_max_price) {
          var max_price =communityComponent.community_resi_max_price
        }
      }
    }

    const product_script = document.createElement("script")

    // Structure Data script start
    product_script.type = "application/ld+json"

    product_script.innerHTML = `{
      "@context":"http://schema.org",
      "@type":"Product",
      "name":"${comm_title}",
      "offers":{
        "@type":"AggregateOffer",
        "lowPrice":"${min_price}",
        "highPrice":"${max_price}",
        "priceCurrency":"USD"
      }
    }`

    //  Structure Data script end
    document.head.appendChild(product_script)
  }

  componentDidMount() {
    axios.get(apiConfig().API_URL+'/community_info/'+ this.props.slug)
    .then(response => {
      let recentSearch = Cookies.get('recent_search')
      const title = response.data.data.community_details.title

      if (recentSearch) {
        let alreadyHaveCookie = false
        recentSearch = JSON.parse(recentSearch)
        const temp = {searchText:title,url:window.location.href}

        recentSearch.map(function(v) {
          if (temp.searchText == v.searchText) {
            alreadyHaveCookie = true
          }
        })

        if (alreadyHaveCookie == false) {
          recentSearch.push(temp)
          Cookies.set('recent_search',recentSearch,{ expires: 7 })
        }
      } else {
        Cookies.set('recent_search', [{searchText:title,url:window.location.href}],{ expires: 7 })
      }

      this.setState({
        community_component : response.data.data,
        success: response.data.success,
        message: response.data.message
      })

      window.lazy.update()
      window.swipersInit()
    })

    window.JQUERY('.top-scroll').addClass('dynamic')

    window.CallTrk.swap()

    if (window.location.href.indexOf("#") === -1) {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    if (this.state.success) {
      window.dataLayer.push({
        'event': 'communityId',
        'divisionName': this.state.community_component.community_details.title ? this.state.community_component.community_details.title : "",
        'e1CommunityId': this.state.community_component.community_details.e1_community_id ? this.state.community_component.community_details.e1_community_id : "",
        'salesforceId': this.state.community_component.community_details.communityOfInterest ? this.state.community_component.community_details.communityOfInterest : ""
      })
    }

    if (this.state.community_component.community_details.global_component <= 0 ) {
      if (window.location.href.indexOf("?faq") > -1  && document.getElementsByClassName('faq-section').length > 0) {
        $('html, body').animate({
          scrollTop: $(".faq-section").offset().top
        }, 1000)
      }

      if (window.location.href.indexOf("?qmi") > -1 && document.getElementsByClassName('qmi-card-section').length > 0) {
        $('html, body').animate({
          scrollTop: $(".qmi-card-section").offset().top
        }, 1000)
      }
    }

    window.CallTrk.swap()

    if (window.location.href.indexOf("#") === -1) {
      window.scrollTo(0, 0)
    }
  }
  render() {
    const community_component = this.state.community_component;
   
    const communityComponent = this.state.community_component.community_details;
    console.log(communityComponent);
    let component
    let count_global_class = ''
    const message = this.state.message
    const state_name = this.props.state.toLowerCase()
    const success = this.state.success
    const submarket_name = this.props.submarket.toLowerCase()
    const window_width = window.screen.width

    window.joinModalDataReady = false

    if (communityComponent) {
      window.joinModalData = {
        title: communityComponent.title,
        submarket: submarket_name.replace(/-/g, ' '),
        communityOfInterest : communityComponent.communityOfInterest,
        division_name : communityComponent.division_name,
      };

      document.dispatchEvent(new CustomEvent('joinModalDataReady'));
      window.joinModalDataReady = true
    }


    if (communityComponent) {
      if (communityComponent.global_component > 0 && communityComponent.global_component < 4) {
          count_global_class = ''
      } else {
        count_global_class = 'community'
      }
    }

    var current_location = window.location.href;
    if (current_location.includes('#') || current_location.includes('?')) {
      var canonical_location = window.location.origin + window.location.pathname  ;
    }else{
        canonical_location = current_location;
    }
    var yoast_following = community_component.yoast_following;
    var yoast_indexing = community_component.yoast_indexing;
    var context_yoast = '';
    if (yoast_indexing === 'true' && yoast_following === 'true') {
      var context_yoast = 'index, follow';
    }else if (yoast_indexing === 'true' && yoast_following === 'false') {
      var context_yoast = 'index, nofollow';
    }else if (yoast_indexing === 'false' && yoast_following === 'true') {
      var context_yoast = 'noindex, follow';
    }else if (yoast_indexing === 'false' && yoast_following === 'false') {
      var context_yoast = 'noindex, nofollow';
    }

    if (communityComponent) {
      this.local_business_structured_data(communityComponent);
      this.local_product_structured_data(communityComponent);
      component = communityComponent.component;

      for (var x in component) {
        if (component[x].acf_fc_layout == "global_faq_section") {
          component.push(component.splice(x,1)[0])
        }
      }

      for (var x in component) {
        if (component[x].acf_fc_layout == "mpc_container") {
          component.push(component.splice(x,1)[0])
        }
      }
    }
    
    const bgColor = communityComponent?.custom_theme?.color_palette?.theme_color_palette_bg ?? '#FAFAFA';
    const primary = communityComponent?.custom_theme?.color_palette?.theme_color_palette_primary ?? '#bdd437';
    const fontName = communityComponent?.custom_theme?.fonts?.theme_fonts_font_family;
    
    const styles = {
        'background-color': bgColor,
        '--bg-color': bgColor,
        '--btn-background-color': primary,
        '--text-color':  communityComponent?.custom_theme?.color_palette?.theme_color_palette_text ?? '#4C4D4C',
        '--btn-color': tinycolor(primary).isLight() ? '#000' : '#fff',
        '--btn-color-hover': tinycolor(primary).isLight() ? '#000' : '#fff',
        '--btn-background-color-hover': tinycolor(primary).isLight() ? tinycolor(primary).darken(5).toString() : tinycolor(primary).lighten(5).toString(),
    }
    
    if (fontName) {
        styles['--font-family'] = fontName;
    }

    return (
      <>
        <Helmet>
          <title>{community_component.yoast_title}</title>
          <meta name="description" content={community_component.yoast_desc} />
          <meta name="robots" content={context_yoast} />
          <meta property="og:title" content={community_component.yoast_title ? community_component.yoast_title : ''} />
          <meta property="og:description" content={community_component.yoast_desc ? community_component.yoast_desc : ''} />
          <meta property="og:image" content={community_component.yoast_image}/>
          <link rel="canonical" href={canonical_location} />
          <meta property="og:url" content={canonical_location} />
          <meta property="og:type" content='Website' />
        </Helmet>
        <div dangerouslySetInnerHTML={{__html: communityComponent?.custom_theme?.fonts?.theme_fonts_google}}/>

        <div role="main" className="Community-main" style={styles}>
          {success === true ? [
            state_name === community_component.community_details.state_abb.toLowerCase() && submarket_name === community_component.community_details.submarket.toLowerCase() ? [
              community_component.community_details.community_status !== 'Archive' ? [
                community_component.community_details ? (
                  <>
                    <section class="hero hero--with-subnav">
                      <Hero
                        data={community_component.community_details}
                        posttype="mpc"
                      />

                      <HeroSubNav
                        data={community_component.community_details}
                        page_data={community_component}
                        posttype="mpc"
                        sticky={false}
                      />

                      <HeroSubNav
                        data={community_component.community_details}
                        page_data={community_component}
                        posttype="mpc"
                        sticky={true}
                      />
                    </section>

                    <div data-sticky-subnav-threshold></div>
                  </>
                ):(''),

                community_component.community_details.long_desc ? (
                  <LargeCopyWithDetails
                    data={community_component.community_details}
                    posttype="mpc"
                  />
                ):(''),

                community_component.neighborhood ? (
                  <MediaFeaturedGrid
                    data={community_component.neighborhood}
                    eyebrow='Neighborhoods'
                    headline_part_1='Find Your'
                    headline_part_2='Home'
                    id="neighborhood"
                    posttype="mpc" />
                ):(''),

                community_component.community_details.promotion_info ? (
                  <PromotionBlock
                    promo_data={community_component.community_details.promotion_info}
                  />
                ):(''),

                community_component.community_details.global_component > 0 ? (
                  <GlobalField
                    global_component={component}
                    customTheme={community_component.community_details?.custom_theme}
                    community_details={community_component.community_details}
                    focus_boxes_horizontal={true}
                    focus_boxes_section_id_fallback='neighborhood'
                    posttype={count_global_class}
                    slice_start={0}
                    slice_end={4}
                  />
                ):(''),

                community_component.qmi ? (
                  <MediaFeaturedGrid
                    data={community_component.qmi}
                    id="available-homes"
                    posttype="mpc"
                  />
                ):(''),

                community_component.community_details.global_component >=4 ? (
                  <GlobalField
                    global_component={component}
                    community_details={community_component.community_details}
                    customTheme={community_component.community_details?.custom_theme}
                    focus_boxes_horizontal={true}
                    focus_boxes_section_id_fallback='neighborhood'
                    posttype='mpc'
                    slice_start={4}
                  />
                ):(''),
                ,
                (window_width < 767) ? (
                  <UtilityNav
                    sfdc_chat={community_component.community_details.sfdc_chat_details}
                    agent_info={community_component.community_details.agent_info}
                    community_component={community_component.community_details}
                    posttype='mpc'
                  />
                ):(
                  <NhsDesktop
                    sfdc_chat={community_component.community_details.sfdc_chat_details}
                    agent_info={community_component.community_details.agent_info}
                    community_component={community_component.community_details}
                    posttype='mpc'
                  />
                )
              ] : <Redirect to={community_component.community_details.submarket_permalink}/>
            ] : [ <Redirect to={<NoMatch/>} /> ]
            ]:[ message != 'Not Completed' ? <SkeletonComp dynamicClass="dynamic"/> : <Redirect to={<NoMatch/>} />
          ]}
        </div>
      </>
    )
  }
}

export default Community
