import React from 'react'
import HeadingSection from './HeadingSection'
import './GridSection.scss'
import SectionHeader from "./SectionHeader";
import {Link} from "react-router-dom";

class GridSection extends React.Component {
  componentDidMount() {
    const section_id = this.props.grid_data.section_id && this.props.grid_data.section_id.trim()

    if (section_id) {
      setTimeout(function () {
        if (window.location.href.indexOf("#" + section_id) > -1) {
          document.querySelector("#" + section_id).scrollIntoView({
            behavior: 'smooth', block: 'start'
          })
        }
      }, 2000)
    }

    window.lazy.update()
  }

  render() {
    const grid_data = this.props.grid_data
    const section_id = grid_data.section_id && grid_data.section_id.trim()

    return (
      grid_data.focus_box_content.length > 0 ? [
        <div className="grid-section"
          id={section_id}>
          {grid_data.headline && (
            <div className="grid-section__header wrap">
              <div className="heading-section-left">
                <SectionHeader
                  eyebrow={grid_data.sub_text}
                  headline_size='large'
                  headline_part_1={grid_data.headline}
                  headline_part_2={grid_data.headline_part_2}
                  headline_part_1_color={grid_data.headline_part_1_color}
                  headline_part_2_color={grid_data.headline_part_2_color}
                  headline_part_2_class='title__highlight'
                />
              </div>

              <div className="heading-section-right">
                {grid_data.description && (
                  <div className="description" dangerouslySetInnerHTML={{__html:grid_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                )}
                {grid_data.cta_label &&grid_data.cta_link && (
                  <Link to={grid_data.cta_link}>
                    {grid_data.cta_label}
                  </Link>
                )}
              </div>
            </div>
          )}
          <div className="row">
            {grid_data.focus_box_content.map(items => (
              items.acf_fc_layout === "image" ? (
                items.image.url ? (
                  <div className="col-lg-4 col-sm-6 grid-img">
                    <img className="grid-icon lazy"
                      data-src={items.image.url}
                      alt={items.image.alt ? items.image.alt : items.image.title}
                    />
                  </div>
                ):('')
              ):(
                items.headline || items.description ?(
                  <div className="col-lg-4 col-sm-6 grid-info"
                    style={{
                      backgroundColor: items.section_bg_color
                    }}>
                    <div className="grid-section-content">
                      <HeadingSection
                        title_part_1={items.headline}
                        title_part_2={items.headline_part_2}
                        headline_part_1_color={items.headline_part_1_color}
                        headline_part_2_color={items.headline_part_2_color}
                        headline_size={items.hasOwnProperty('headline_size') ? items.headline_size : 'large'}
                      />

                      {items.description ?(
                          <div className="title-info" dangerouslySetInnerHTML={{__html:items.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                      ):('')}

                      {items.cta_label && items.cta_link ? (
                        <a href={items.cta_link}  className="learn-more" title={items.cta_label}>{items.cta_label}</a>
                      ):('')}
                    </div>
                  </div>
                ):('')
              )
            ))}
          </div>
        </div>
      ] : ['']
    )
  }
}

export default GridSection
